<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-fyber">
        <img src="@/assets/fyber-logo.svg" alt="Fyber">
        <span class="sep"></span>
        <p>Fyber connects app developers and media companies with advertisers through the power of technology. Across every device. We are an independent advertising technology company devoted to
          delivering global audiences at scale through a powerful cross-platform monetization &amp; advertising solution. Our SSP, Ad Server, Exchange and Mediation products empower thousands of the
          world’s leading app developers and publishers to generate business-critical revenue streams and serves over half a billion monthly active users globally.
        </p>
      </div>
    </div>

    <div class="laptop-img fyber-laptop">
      <div class="grid-container">
        <img src="@/assets/fyber-main-img.png" alt="Fyber">
      </div>
    </div>

    <div class="container-about about-fyber">
      <div class="grid-container">
        <img src="@/assets/fyber-diamond.svg" alt="Fyber">
        <h3>About the project</h3>
        <p>We’ve are working closely with the marketing department to make sure that Fyber’s web presence is top notch. While we focus and keep everything updated, Fyber is
          focusing on growing the company into a media giant alongside its sister company Inneractive.<br>
          Our partnership with this great company comes as a certification of our evolution and a huge compliment since their standards are as high as it gets.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-services__content">
          <h3>What we do</h3>

          <div class="wwd-left">
            <h4>Managed services</h4>
            <img src="@/assets/web-app.svg" width="60px" height="60px">
            <h4>Support</h4>
            <img src="@/assets/maintenance-icon.svg" width="60px" height="60px">
            <h4>SEO</h4>
            <img src="@/assets/consultancy-icon.svg" width="60px" height="60px">
          </div>

          <span class="sep vertical"></span>

          <div class="wwd-right">
            <img src="@/assets/managed-services.svg" width="60px" height="60px">
            <h4>Custom Web App's</h4>
            <img src="@/assets/support-icon.svg" width="60px" height="60px">
            <h4>Maintenance</h4>
            <img src="@/assets/seo-icon.svg" width="60px" height="60px">
            <h4>Consultancy</h4>
          </div>
        </div>
      </div>
    </div>


    <testimonial tsTitle="#22D593">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Michael Jennings, Sr. Creative Director</p>
        <p class="testimonial-text__content">"We work closely with Scriptics to help us develop and optimize our site and digital marketing channels. I would highly recommend working with Vali and the Scriptics team and consider them a valued partner”
        </p>
      </div>
    </testimonial>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'FyberInvestors'}" class="right">
              <img src="@/assets/btn-investors.jpg" alt="Investor Section">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{  name: 'Hape'}" class="left">
              <img src="@/assets/casestudy/hape-main-img.png" alt="Wayspa">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#22D593" colorTitle="#fafafa" colorButton="#fafafa"></ContactUs>
    <Footer></Footer>
  </div>

</template>
<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: 'Fyber',
  components: {LetsChat, Testimonial, ContactUs, Footer},
}
</script>
<style lang="scss">

.logo-fyber img {
  max-width: 320px;
}

.fyber-laptop {
  background: #2d2d2d;
}
.about-fyber {
  background: #22D593;
}
.container-about h3, .container-about p {
  color: #fafafa;
}
.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #22D593 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>